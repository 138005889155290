@import '../../../../../variables';
@import '../../../../../mixins';

.personal-dictionary-full {
  .horizontal-line {
    position: absolute;
    top: 0px;
    width: 100%;
    padding: .5px;
    z-index: 10;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), white, rgba(0, 0, 0, 0));
  }
  form {
    box-shadow: 0px -1px 15px 0px rgba(0,0,0,0.5);
    position: absolute;
    background-color: $main;
    bottom: 0px;
    height: 90px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: calc(100% - 7px);
    flex-direction: column-reverse;
    // border-top: 1px solid #333;
    .fas-arrows-alt-h {
      color: white;
    }
    button {
      height: 60px;
      border: none;
      color: $main;
      border-radius: 3px;
      background-color: white;
      font-weight: 600;
      font-size: 1em;
      width: 90%;
      margin: 10px;
      &:hover {
        cursor: pointer;
      }
    }
    .questions-flag {
      width: 70px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 15px;
      padding-bottom: 2px;
      &:hover {
        cursor: pointer;
      }
    }
  }
}
.terms-container {
  position: relative;
  overflow: auto;
  width: 100%;
  height: 100%;
  padding-bottom: 75px;
  .terms-title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 0px;
    width: 100%;
    .fa-play {
      padding: 10px;
      color: $colorSuccess;
      &:hover {
        cursor: pointer;
      }
    }
    .title {
      padding: 5px;
      font-weight: 300;
      font-size: 17px;
      margin-top: 15px;
    }
    .fa-times-circle {
      position: absolute;
      right: 2px;
      top: 5px;
      padding: 10px;
      &:hover {
        cursor: pointer;
        color: $colorDanger;
      }
    }
  }
  .terms {
    overflow: auto;
    position: relative;
    color: white;
    display: flex;
    flex-direction: column;
    list-style-type: none;
    text-align: left;
    margin-top: 10px;
    padding: 0px 20px 20px 20px;
    .terms-empty {
      height: 300px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      opacity: .3;
      text-align: center;
    }
  }
}
