@import '../../../../../variables';
@import '../../../../../mixins';

.learn-container {
  position: relative;
  overflow: auto;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
  align-content: space-between;
  .fa-arrow-left {
    position: absolute;
    top: 10px;
    left: 10px;
    &:hover {
      cursor: pointer;
      color: $colorDanger;
    }
  }
  .terms-to-learn {
    position: absolute;
    top: 10px;
  }
  .fa-times-circle {
    position: absolute;
    top: 10px;
    &:hover {
      cursor: pointer;
      color: $colorDanger;
    }
  }
  .question {
    margin-top: 30px;
    width: 95%;
    height: 70px;
    background-color: white;
    color: $main;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
  }
  .answers {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-content: space-between;
    align-items: center;
    width: 100%;
    height: 35%;
  }
  .buttons {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    .learn-end {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40%;
      height: 50px;
      border-radius: 5px;
      background-color: white;
      color: $main;
      &:hover {
        cursor: pointer;
        background-color: $colorDanger;
      }
    }
    .next {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40%;
      height: 50px;
      border-radius: 5px;
      background-color: white;
      color: $main;
      &:hover {
        cursor: pointer;
      }
      .fa-play {
        color: $main;
        margin: 10px;
      }
    }
    .next-lock {
      background-color: rgba(250,250,250,0.7);
      &:hover {
        cursor: not-allowed;
      }
    }
  }
}
