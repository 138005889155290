@import '../../../../../variables';
@import '../../../../../mixins';

.personal-dictionary-widget {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
  background-color: $main;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  @include box_shadow(3);
  transition-timing-function: cubic-bezier(.25,.8,.25,1);
  transition: box-shadow .3s, width .6s, height .6s, border-radius .6s;
  &:hover {
    @include box_shadow(4);
    cursor: pointer;
  }
  .fa-edit-container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    .fa-edit {
      position: relative;
      top: -2px;
      right: -2px;
    }
  }
}
