@import '../../../mixins';
@import '../../../variables';

.course-navigation-tabs {
  background-color: rgb(250,250,250);
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-weight: 500;
  font-size: 20px;
  height: 50px;
  position: relative;
  flex-direction: row;
  background-color: transparent;
  padding-right: 50px;
  .course-navigation-tab {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    width: auto;
    @include breakpoint(laptop) {
      padding: 0px 10px;
    }
    color: $main;
    border: 2px solid transparent;
    border-radius: 3px;
    .absolute-wrapper {
      position: absolute;
      right: 50px;
      top: 70px;
    }
    .fa-power-off {
      padding-left: 10px;
    }
    .fa-arrow-circle-left {
      padding-left: 10px;
    }
  }
  .course-navigation-tab-logout {
    &:hover {
      cursor: pointer;
    }
  }
}

.profile-menu-slide-top-enter {
  transform: translateY(-10%);
  opacity: 0;
}
.profile-menu-slide-top-enter-active {
  transform: translateY(0px);
  opacity: 1;
  transition: transform 300ms cubic-bezier(.25,.8,.25,1), opacity 300ms cubic-bezier(.25,.8,.25,1);
}
.profile-menu-slide-top-exit {
  opacity: 1;
  transform: translateY(0px);
}
.profile-menu-slide-top-exit-active {
  opacity: 0;
  transform: translateY(-10%);
  transition: transform 300ms cubic-bezier(.25,.8,.25,1), opacity 300ms cubic-bezier(.25,.8,.25,1);
}
