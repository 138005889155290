@import 'src/variables';
@import 'src/mixins';

.reset-password-container {
  .password {
    input {
      width: 300px;
      height: 40px;
      border-radius: 3px;
      border: 1px solid $main;
      margin: 10px;
      text-indent: 10px;
    }
  }
}
