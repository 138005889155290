@import '../../../../variables';
@import '../../../../mixins';

.course-menu {
  background-color: white;
  border-right: 1px solid #efefef;
  overflow: auto;
  color: $mainDark;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 25px 20px 0px 20px;
  @include breakpoint(laptop) {
    padding: 25px 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 500px;
    height: 100vh;
    .course-advanced {
      padding-bottom: 100px;
    }
  }
  .course {
    width: auto;
    height: auto;
    ul {
      list-style-type: none;
      li {
        text-align: left;
        padding: 4px;
      }
    }
    .course-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      height: 50px;
      z-index: 2;
      background-color: white;
      font-size: 14px;
      font-weight: 600;
      color: $contrastColorDark;
      min-width: 210px;
      &:hover {
        cursor: pointer;
      }
    }
    .course-elements-container {
      overflow: hidden;
      z-index: 1;
      height: auto;
      .absolute-wrapper {
        z-index: 2;
        position: relative;
        height: auto;
        width: 100%;
        .lock-container {
          position: absolute;
          top: 0px;
          left: 0px;
          bottom: 0px;
          right: 0px;
          background-color: rgba(250,250,250,0.4);
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 2;
          color: $main;
        }
        .course-elements {
          margin-top: 0px;
          margin-bottom: 12px;
          padding: 0px;
          background-color: white;
          display: block;
          position: relative;
          z-index: 1;
          .course-element {
            width: auto;
          }
          .course-element:hover {
            cursor: pointer;
            font-weight: 600;
          }
          .course-selected {
            background-color: rgba(223, 237, 249,.9)
          }
        }
      }
    }
  }
}

.slide-top-enter {
  transform: translateY(-100%);
}
.slide-top-enter-active {
  transform: translateY(0px);
  transition: transform 400ms linear;
}
.slide-top-exit {
  transform: translateY(0px);
}
.slide-top-exit-active {
  transform: translateY(-100%);
  transition: transform 400ms linear;
}

