@import '../../../variables';

.error-container {
  position: relative;
  top: -50px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30%;
  margin: auto;
  text-align: left;
  height: 100vh;
  ul {
    list-style-type: decimal;
  }
  .error {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: $main;
    text-align: center;
  }
}
