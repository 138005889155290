html, body {
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
  width: 100%;
  height: auto;
  overflow-x: hidden;
}

a {
  color: transparent;
}

button::-moz-focus-inner {
  border: 0;
}
