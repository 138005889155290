@import 'src/variables';
@import 'src/mixins';

.shop-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  .purchase-error {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    border-radius: 3px;
    color: $colorDanger;
    padding: 20px 30px;
    line-height: 3em;
    p {
      font-size: 1.5em;
    }
    .shop-button {
      position: relative;
      top: 10px;
      background-color: #fff;
      color: $main;
      font-weight: 500;
      font-size: 16px;
      margin-top: 20px;
      padding: 25px 40px;
      border-radius: 3px;
      border-width: 0px;
      font-weight: 600;
      border: 2px solid $main;
      z-index: 3;
      outline: none;
      transition: box-shadow .2s;
      box-shadow: 1px 4px 20px -2px rgba(0,0,0,0.6);
      &:hover {
        cursor: pointer;
        box-shadow: 1px 4px 15px -2px rgba(0,0,0,0.6);
      }
      &:active {
        cursor: pointer;
        box-shadow: 1px 4px 10px -2px rgba(0,0,0,0.6);
      }
    }
  }
  .purchase-confirmation {
    position: relative;
    top: -15px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 3px;
    color: $colorSuccess;
    padding: 40px 50px;
    font-size: 1.3em;
    .course-button {
      position: relative;
      top: 10px;
      background-color: #fff;
      color: $main;
      font-weight: 500;
      font-size: 16px;
      margin-top: 20px;
      padding: 25px 40px;
      border-radius: 3px;
      border-width: 0px;
      font-weight: 600;
      border: 2px solid $main;
      z-index: 3;
      outline: none;
      transition: box-shadow .2s;
      box-shadow: 1px 4px 20px -2px rgba(0,0,0,0.6);
      &:hover {
        cursor: pointer;
        box-shadow: 1px 4px 15px -2px rgba(0,0,0,0.6);
      }
      &:active {
        cursor: pointer;
        box-shadow: 1px 4px 10px -2px rgba(0,0,0,0.6);
      }
    }
  }
}
