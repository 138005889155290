@import 'src/variables';

.answer {
  box-sizing: border-box;
  width: 45%;
  height: 70px;
  background-color: white;
  color: $main;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  font-size: .9em;
  word-break: break-word;
  padding: 2px;
  &:hover {
    cursor: pointer;
  }
}
.answer-correct {
  background-color: $colorSuccess;
}
.answer-correct-show {
  background-color: $colorSuccess;
  animation: blinker 1s linear infinite;
}
.answer-wrong {
  background-color: $colorDanger;
}

@keyframes blinker {
  50% {
    background-color: white;
  }
}
