@import '../../../mixins';

.offer-option-container {
  position: relative;
  width: 90%;
  margin: 30px 0px 25px;
  height: auto;
  color: $mainDark;
  perspective: 1000px;
  transition: transform .1s linear;
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
  @include breakpoint(tablet) {
    height: auto;
    width: 80%;
  }
  @include breakpoint(laptop) {
    height: 505px;
    width: 28%;
    max-width: 500px;
  }
  @include breakpoint(desktop) {
    height: 605px;
    width: 28%;
    max-width: 500px;
  }
  .offer-option {
    position: relative;
    width: 100%;
    text-align: center;
    transition: transform 0.6s linear;
    transform-style: preserve-3d;
    border: 1px solid rgba(0,0,0,0.1);
    border-radius: 4px;
    @include box_shadow(3);
    height: 550px;
    @include breakpoint(tablet) {
      height: 450px;
    }
    @include breakpoint(laptop) {
      height: 100%;
    }
  }
  .offer-back, .offer-front {
    border-radius: 4px;
    box-sizing: border-box;
    padding: 10px 4px 150px 4px;
    background-color: #fff;
    color: $mainDark;
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    width: 100%;
    height: auto;
    -webkit-perspective: 0;
    -webkit-backface-visibility: hidden;
    -webkit-transform: translate3d(0,0,0);
    visibility:visible;
    backface-visibility: hidden;
    h5 {
      height: 45px;
    }
  }
  .offer-back {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    transform: rotateY(180deg);
    padding: 40px 15px 82px 15px;
    .description {
      position: absolute;
      display: flex;
      justify-content: center;
      flex-direction: column;
      .back-list-container {
        display: flex;
        justify-content: center;
        .back-list {
          position: relative;
          text-align: left;
          list-style-type: none;
          padding: 0px;
          li {
            padding: 1px;
          }
        }
      }
    }
    .caption {
      position: absolute;
      bottom: 15px;
      left: 5px;
      font-size: 0.7em;
    }
  }
  .offer-front {
    .offer-line {
      position: relative;
      top: 5px;
      height: 1px;
      width: 100%;
      background-color: #efefef;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    .offer-verte {
      @include box_shadow(2);
      position: relative;
      top: -15px;
      width: 95px;
      border-radius: 4px;
      padding: 2px 0px;
      background-color: $main;
      color: white;
      border: 1px solid $main;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto;
      -webkit-perspective: 0;
      -webkit-backface-visibility: hidden;
      -webkit-transform: translate3d(0,0,0);
      visibility:visible;
      backface-visibility: hidden;
      &:hover {
        cursor: pointer;
        & > .offer-verte-icon > .fa {
          animation-name: example;
          animation-duration: 1s;
          animation-fill-mode: forwards;
        }
      }
      .offer-verte-text {
        width: 50%;
        display: inline-block;
      }
      .offer-verte-icon {
        display: inline-block;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .fa {
        position: relative;
        left: 4px;
        padding: 0px 4px;
      }
    }
    .fa-info-circle {
      position: relative;
      top: -10px;
      font-size: 24px;
      border-radius: 100%;
      box-shadow: 1px 0px 15px -2px rgba(0,0,0,0.6);
      &:hover {
        cursor: pointer;
      }
    }
  }
  h4 {
    margin-top: 18px;
    margin-bottom: -10px;
    color: $mainLight;
    font-size: 17px;
    @include breakpoint(desktop) {
      font-size: 18px;
      margin-top: 25px;
    }
  }
  h5 {
    color: $main;
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 25px;
    @include breakpoint(desktop) {
      font-size: 15px;
      margin-bottom: 35px;
    }
  }
  .list-container {
    position: relative;
    top: -30px;
    line-height: 1.4em;
    backface-visibility: hidden;
    @include breakpoint(tablet) {
      line-height: 1.3em;
    }
    @include breakpoint(laptop) {
      line-height: 1.15em;
    }
    @include breakpoint(desktop) {
      top: 0px;
    }
    li {
      padding: 11px;
      @include breakpoint(desktop) {
        padding: 14px;
      }
    }
  }
  hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #E5E5E5;
    margin: 1em 0;
    padding: 0;
  }
  .price-container {
    position: absolute;
    bottom: 13px;
    left: 0px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    backface-visibility: hidden;
    .disabled {
      &:hover {
        cursor: not-allowed !important;
      }
    }
    .price {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 90%;
      height: 60px;
      border: 1px solid $mainLight;
      border-radius: 3px;
      font-size: 1.3em;
      font-weight: 500;
      @include box_shadow(2);
      &:hover {
        cursor: pointer;
      }
    }
    .best-price {
      background-color: $mainLight;
      border: 2px solid $mainLight;
      color: white;
      font-size: 1.5em;
      font-weight: 600;
      .present-price {
        position: relative;
        left: -15px;
      }
      .old-price {
        position: relative;
        top: -10px;
        left: -25px;
        @include breakpoint(laptop) {
          left: -25px;
        }
        text-decoration: line-through;
        font-size: 1.2rem;
        font-weight: 500;
      }
    }
  }
  .fa-check {
    color: $contrastColorLight;
  }
}

@keyframes example {
  from {transform: rotate(0deg)}
  to {transform: rotate(-360deg)}
}

