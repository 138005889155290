@import '../../../../variables';
@import '../../../../mixins';

.personal-dictionary {
  overflow: visible;
  height: 300px;
  position: absolute;
  color: white;
  &:hover {
    .move-dictionary {
      opacity: 1;
    }
  }
  .fa {
    color: rgba(250,250,250,0.99);
  }
}

.personal-dictionary-content {
  background-color: transparent;
  overflow: auto;
  .move-dictionary {
    overflow: visible;
    opacity: 0;
    position: absolute;
    top: -30px;
    left: -30px;
    transition: opacity .6s;
    .fa-arrows-alt {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $main;
      border-radius: 50%;
      height: 30px;
      width: 30px;
      border: 2px solid white;
      z-index: 3;
      &:hover {
        cursor: move;
      }
    }
  }
}

.personal-dictionary-open-content {
  height: 100%;
  width: 100%;
  // overflow: scroll;
  &:hover {
    cursor: move;
  }
}

.personal-dictionary-widget-content {
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  .move-dictionary {
    top: -10px;
    left: -10px;
  }
  .new-terms {
    position: absolute;
    bottom: -10px;
    right: -10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $main;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    z-index: 3;
    border: 2px solid white;
    transition: .6s opacity;
    opacity: 1;
  }
  .new-terms-hidden {
    opacity: 0;
  }
}

.personal-dictionary-open {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
  background-color: $main;
  border-radius: 5px;
  transition: width .6s, height .6s, border-radius .6s;
  @include box_shadow(2);
  .personal-dictionary-open-content {
    overflow-y: scroll;
  }
  .fa-times-circle {
    position: absolute;
    top: 15px;
    right: 10px;
    z-index: 3;
    &:hover {
      cursor: pointer;
    }
  }
}
