@import '../../../../../variables';
@import '../../../../../mixins';

.term {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px;
  font-size: .9em;
  .fa-trash {
    display: none;
    padding-left: 10px;
    color: $colorDanger;
  }
  &:hover {
    color: $colorDanger;
    cursor: pointer;
    .fa-trash {
      display: block;
    }
  }
}
