@import '../../mixins';
@import '../../variables';

.course-content {
  margin-top: 0px;
  height: 150%;
  background-color: white;
  width: auto;
  position: relative;
  z-index: 2;
  padding-top: 80px;
  padding-bottom: 50px;
  @include breakpoint(laptop) {
    padding-top: 100px;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  -webkit-transform: translateZ(0);
  overflow: hidden;
  font-size: 17px;
  .course-title {
    -webkit-transform:translate3d(0,0,0);
    background-color: inherit;
    color: $main;
    height: 20px;
    padding-bottom: 10px;
    z-index: 3;
    width: 100%;
    position: relative;
    top: -20px;
    h2 {
      font-weight: 300;
    }
  }
  .path {
    -webkit-transform:translate3d(0,0,0);
    position: relative;
    width: 3px;
    padding: 0px;
    margin: 100px;
    background-color: $main;
    list-style-type: none;
    .step {
      margin-bottom: 125px;
      .icon {
        position: relative;
        left: -110px;
        top: -20px;
        color: $main;
      }
      .step-pointer {
        position: relative;
        left: -6px;
        top: -55px;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        border: 3px solid $main;
        background-color: white;
      }
      .step-description-container {
        color: $contrastColorDark;
        position: relative;
        left: 50px;
        top: -82px;
        min-width: 100px;
        @include breakpoint(tablet) {
          min-width: 150px;
        }
        @include breakpoint(laptop) {
          width: 220px;
        }
        .step-description {
          position: absolute;
          text-align: left;
        }
      }
    }
  }
}
