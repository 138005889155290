@import '../../../../variables';
@import '../../../../mixins';

.terminology-basic {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  overflow-x: visible;
  color: $mainDark;
  border-right: 1px solid #efefef;
  width: 100%;
  height: 100vh;
  @include breakpoint(laptop) {
    height: 100vh;
  }
  .chapter-title {
    padding: 0px 0px 7px 0px;
    @include breakpoint(laptop) {
      padding: 23px 0px 7px 0px;
    }
    width: 70%;
    color: $contrastColorDark;
    font-weight: 500;
    font-size: 26px;
  }
  .chapter-text {
    padding-bottom: 50px;
    width: 90%;
    height: auto;
    text-align: left;
    white-space: pre-wrap;
    .chapter-term {
      position: relative;
      display: inline;
      width: auto;
      font-size: 16px;
      font-family: 'Lato', sans-serif;
      padding: 0px !important;
      .term-gap {
        color: $mainDark;
        &:hover {
          cursor: auto;
        }
      }
      .term-hoverable {
        font-size: 16px;
        font-family: 'Lato', sans-serif;
        position: relative;
        text-decoration: underline;
        &:hover {
          cursor: pointer;
          background-color: rgba(223, 237, 249,.9);
          color: $contrastColorDark;
          .term-dictionary-container {
            display: inline-block;
          }
        }
      }
      .term-already-hoverable {
        text-decoration: none;
        color: $mainDark;
        &:hover {
          background-color: white;
          color: $mainDark;
          cursor: auto;
        }
      }
      .term-dictionary-container {
        display: none;
        white-space: pre-wrap;
        position: absolute;
        top: 0px;
        left: 0px;
        width: auto;
        .term-dictionary {
          position: absolute;
          bottom: 0px;
          left: 0px;
          width: auto;
          background-color: $main;
          color: white;
          padding: 5px 7px;
          border-top-left-radius: 3px;
          border-bottom-left-radius: 3px;
          @include breakpoint(laptop) {
            border-radius: 3px;
          }
          .term-add-mobile {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 0px;
            right: -25px;
            width: 25px;
            height: 100%;
            background-color: $main;
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;
            border-left: 1px solid white;
            .fa-plus {
              color: white;
              font-size: .8em;
            }
          }
        }
      }
    }
  }
  .horizontal-line {
    width: 100%;
    padding: .5px;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), $main, rgba(0, 0, 0, 0));
  }
  .chapter-questions {
    width: 90%;
    margin-top: 25px;
    padding-bottom: 150px;
    @include breakpoint(laptop) {
      padding-bottom: 70px;
    }
    text-align: left;
    line-height: 1.5em;
    .chapter-questions-header {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-left: 10px;
      .fa-question-circle {
        margin: 15px;
      }
    }
    ul {
      list-style-type: decimal;
      text-align: left;
      text-indent: 7px;
    }
  }
}

