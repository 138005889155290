@import '../../mixins';
@import '../../variables';

.homepage-content {
  position: fixed;
  top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  z-index: 0;
  -webkit-transform: translateZ(0);
  .homepage-background {
    position: fixed;
    top: 0px;
    height: 100%;
    width: 100%;
    background-size: cover;
    background-image: url('../../assets/bg-small.jpeg');
    @include breakpoint(tablet) {
      background-image: url('../../assets/bg-medium.jpeg');
    }
    @include breakpoint(desktop) {
      background-image: url('../../assets/bg-high.jpeg');
    }
  }
  .homepage-overlay {
    z-index: 1;
    position: fixed;
    top: 0px;
    height: 100%;
    width: 100%;
    /* background-image: linear-gradient(to bottom, transparent 50%, rgba(0,0,0,0.5), rgba(0,0,0,0.8) 100%); */
    /* background-image: linear-gradient(to right, transparent 60%, rgba(5, 101, 120, 0.4)); */
    background-image: linear-gradient(to top, rgba(0,66,79,0.8) 1%, transparent, rgba(250,250,250,0.99) 95%);
  }
  .homepage-main {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .homepage-head {
      position: relative;
      top: -50px;
      background: radial-gradient(circle at center, rgba(250,250,250,0.9), transparent 100%);
      z-index: 4;
      padding: 20px;
    }
    .homepage-header-h1 {
      color: $main;
      z-index: 3;
      font-weight: 300;
      font-size: 28px;
      @include breakpoint(tablet) {
        font-size: 29px;
      }
      @include breakpoint(laptop) {
        font-size: 30px;
      }
    }
    .homepage-header-h2 {
      color: $main;
      z-index: 3;
      font-weight: 300;
      font-size: 24px;
      @include breakpoint(tablet) {
        font-size: 25px;
      }
      @include breakpoint(laptop) {
        font-size: 26px;
      }
      span {
        font-weight: 400;
        font-size: 24px;
        @include breakpoint(tablet) {
          font-size: 25px;
        }
        @include breakpoint(laptop) {
          font-size: 26px;
        }
      }
    }
    .homepage-button {
      position: relative;
      top: 10px;
      background-color: $main;
      color: white;
      font-weight: 500;
      font-size: 16px;
      padding: 25px 40px;
      border-radius: 3px;
      border-width: 0px;
      z-index: 3;
      outline: none;
      @include box_shadow(2);
      transition-timing-function: cubic-bezier(.25,.8,.25,1);
      transition: box-shadow .2s, background-color .2s;
      &:hover {
        cursor: pointer;
        background-color: $mainLight;
        @include box_shadow(3);
        transition: box-shadow .3s, background-color .3s;
      }
      &:active {
        cursor: pointer;
        background-color: $contrastColorLight;
        box-shadow: 1px 4px 10px -2px rgba(0,0,0,0.6);
      }
    }
    .homepage-arows {
      position: absolute;
      bottom: 100px;
      z-index: 3;
      &:hover {
        cursor: pointer;
      }
      span {
        position: absolute;
        top: 0;
        left: 50%;
        width: 24px;
        height: 24px;
        margin-left: -12px;
        border-left: 1px solid rgba(250,250,250,1);
        border-bottom: 1px solid rgba(250,250,250,1);
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
        -webkit-animation: arrowDown 2s infinite;
        animation: arrowDown 2s infinite;
        opacity: 0;
        box-sizing: border-box;
        &:nth-of-type(1) {
          -webkit-animation-delay: 0s;
          animation-delay: 0s;
        }
        &:nth-of-type(2) {
          top: 16px;
          -webkit-animation-delay: .15s;
          animation-delay: .15s;
        }
        &:nth-of-type(3) {
          top: 32px;
          -webkit-animation-delay: .3s;
          animation-delay: .3s;
        }
      }
    }
  }
  .newsletter-button {
    // &::after {
    //   position: absolute;
    //   top: -7px;
    //   left: -7px;
    //   content: '1';
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    //   height: 20px;
    //   width: 20px;
    //   background-color: $colorDanger;
    //   border-radius: 50%;
    // }
    position: absolute;
    bottom: 5%;
    right: 5%;
    background-color: $main;
    color: white;
    font-weight: 500;
    font-size: 16px;
    padding: 0px 7px;
    border-radius: 7px;
    border-width: 0px;
    z-index: 10;
    outline: none;
    @include box_shadow(3);
    transition-timing-function: cubic-bezier(.25,.8,.25,1);
    transition: box-shadow .3s, background-color .3s;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-name: bounce-7;
    animation-timing-function: cubic-bezier(0.280, 0.840, 0.420, 1);
    &:hover {
      transition: box-shadow .2s, background-color .2s;
      @include box_shadow(4);
      cursor: pointer;
      cursor: pointer;
      background-color: $mainLight;
      box-shadow: 1px 4px 15px -2px rgba(0,0,0,0.6);
    }
    &:active {
      cursor: pointer;
      background-color: $contrastColorLight;
      box-shadow: 1px 4px 10px -2px rgba(0,0,0,0.6);
    }
  }
}

@keyframes arrowDown {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

  @keyframes bounce-7 {
      0%   { transform: scale(1,1)      translateY(0); }
      10%  { transform: scale(1.1,.9)   translateY(0); }
      30%  { transform: scale(.9,1.1)   translateY(-20px); }
      50%  { transform: scale(1.05,.95) translateY(0); }
      55%  { transform: scale(1,1)      translateY(-1px); }
      65%  { transform: scale(1,1)      translateY(0); }
      100% { transform: scale(1,1)      translateY(0); }
  }
