@import '../../mixins';
@import '../../variables';
@import './hamburgerMenu/HamburgerMenu';
@import './landingNavigationTabs/LandingNavigationTabs';

.landing-navigation-short {
  height: 20px !important;
}

.landing-navigation {
  position: fixed;
  top: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 20px;
  color: white;
  z-index: 3;
  height: 50px;
  font-size: 18px;
  font-family: 'Lato', sans-serif;
  transition: .4s height;
  -webkit-transform: translateZ(0);
  .logo {
    &:hover {
      cursor: pointer;
    }
  }
  @include breakpoint(laptop) {
    .landing-navigation-tab-login {
      border: 2px solid $main;
      border-radius: 4px;
      transition: all .2s;
      &:hover {
        border: 2px solid $main;
        background-color: $main;
        color: white;
        cursor: pointer;
      }
    }
  }
  .landing-navigation-tabs {
    .login-lock {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: inherit;
      .fa-lock {
        position: absolute;
        z-index: 10;
        color: $main;
        font-size: 1.3em;
        &:hover {
          cursor: not-allowed;
        }
      }
      .navigation-tab-login {
        padding: 15px 20px;
        color: $main;
        border: 2px solid $main;
        opacity: 0.5;
        &:hover {
          cursor: not-allowed;
        }
      }
    }
  }
}

.landing-navigation-sticky {
  background-color: $main;
  color: white;
  height: 30px;
  @include box_shadow(2);
  -webkit-transform: translateZ(0);
  .logo {
    .pill {
      .pill-left {
        border: 1px solid white;
        background-color: white;
        .pill-left-shadow {
          background-color: $main;
        }
      }
      .pill-right {
        border: 1px solid white;
        .pill-right-shadow {
          border-color: $main;
          border-top: 1px solid white;
          border-right: 1px solid white;
        }
      }
    }
    .logo-label {
      color: white;
    }
  }
  .landing-navigation-tabs {
    background-color: $main;
    .login-lock {
     position: relative;
     display: flex;
     justify-content: center;
     align-items: center;
     background-color: inherit;
     .fa-lock {
       position: absolute;
       z-index: 10;
       color: white;
       font-size: 1.3em;
       &:hover {
         cursor: not-allowed;
       }
     }
     .navigation-tab-login {
       color: #fff;
       border: 2px solid $main;
       opacity: 0.5;
       &:hover {
         cursor: not-allowed;
       }
     }
   }
    .landing-navigation-tab {
      color: white;
      &:hover {
        border: 2px solid white;
      }
    }
    .landing-navigation-tab-login {
      &:hover {
        border: 2px solid white;
        cursor: pointer;
      }
    }
  }
  .hamburger-menu {
    .hamburger {
      & > span {
        background-color: white;
        &::before, &::after {
          background-color: white;
        }
      }
    }
    .close:focus > span {
      background-color: transparent;
    }
  }
}
