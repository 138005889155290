.hamburger-menu {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  z-index: 10;
  @include breakpoint(laptop) {
    display: none;
  }
  .hamburger {
    display: block;
    position: relative;
    z-index: 5;
    right: 20px;
    top: 0;
    width: 55px;
    height: 55px;
    border: none;
    background-color: transparent;
    color: #00424f;
    text-align: center;
    cursor: pointer;
    & > span {
      display: block;
      position: absolute;
      top: 27px;
      left: 18px;
      right: 18px;
      height: 2px;
      background-color: #00424f;
      box-shadow: 0 0 4px 0px rgba(0, 0, 0, 0.2);
      &::before {
        top: -6px;
      }
      &::after {
        bottom: -6px;
      }
      &::before, &::after {
        content: '';
        position: absolute;
        display: block;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: #00424f;
        box-shadow: 0 0 4px 0px rgba(0, 0, 0, 0.2);
        transition: 0.3s transform ease-in;
      }
    }
    @include breakpoint(laptop) {
      display: none;
    }
  }
  .close:focus > span {
    background-color: transparent;
    &::before {
      transform: translate(1px,6px) rotate(45deg) ;
      width: 18px;
    }
    &::after {
      transform: translate(1px,-6px) rotate(-45deg);
      width: 18px;
    }
  }
}

