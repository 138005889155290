@import '../../../../../variables';
@import '../../../../../mixins';

.results-container {
  position: relative;
  overflow: auto;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  align-content: space-between;
  .fa-arrow-left {
    position: absolute;
    top: 10px;
    left: 10px;
    &:hover {
      cursor: pointer;
      color: $colorDanger;
    }
  }
  .fa-times-circle {
    position: absolute;
    top: 10px;
    &:hover {
      cursor: pointer;
      color: $colorDanger;
    }
  }
  .learn-end {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40%;
    height: 50px;
    margin-top: 35px;
    border-radius: 5px;
    background-color: white;
    color: $main;
    &:hover {
      cursor: pointer;
    }
  }
}
