@mixin breakpoint($class) {
  @if $class == phoneHorizontal {
    @media (min-width: 480px) and (orientation:landscape) { @content; }
  }
  @else if $class == tablet {
    @media (min-width: 768px) { @content; }
  }
  @else if $class == laptop {
    @media (min-width: 1125px) { @content; }
  }
  @else if $class == desktop {
    @media (min-width: 1440px) { @content; }
  }
}

@mixin box_shadow ($level) {
  @if $level == 1 {
      box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  } @else if $level == 2 {
      box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  } @else if $level == 3 {
      box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  } @else if $level == 4 {
      box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  } @else if $level == 5 {
    box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
  }
}
