@import '../../../../variables';
@import '../../../../mixins';

.step {
  transition: transform .6s;
}

.tutorial {
  background-color: white;
  border-right: 1px solid #efefef;
  padding: 25px 20px;
  overflow: auto;
  color: $mainDark;
  width: 100%;
  box-sizing: border-box;
  display: none;
  @include breakpoint(laptop) {
    display: block;
    max-width: 350px;
  }
  height: 100vh;
  transition: width .6s;
  @include breakpoint(laptop) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 500px;
    height: 100vh;
  }
  .label {
    position: absolute;
    top: 35px;
    left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0px 4px 4px 0px;
    background-color: $main;
    width: 65px;
    height: 50px;
    color: white;
    z-index: 5;
    transition: left .6s;
    @include box_shadow(2);
    transition-timing-function: cubic-bezier(.25,.8,.25,1);
    transition: width .2s;
    &:hover {
      width: 75px;
      cursor: pointer;
    }
  }
}

.tutorial-open {
  width: 100%;
  height: 100%;
  @include breakpoint(laptop) {
    height: 100vh;
  }
}

.tutorial-close {
  width: 0%;
  height: 0px;
  border-right: none;
  margin-right: 25px;
}

.self-tutorial-container {
  height: 100%;
  width: 100%;
  position: relative;
  overflow: auto;
  transition: opacity .6s;
  .fa-times-circle {
    position: absolute;
    top: 0px;
    right: 0px;
    &:hover {
      cursor: pointer;
      color: $colorDanger;
    }
  }
  h3 {
    font-weight: 300;
    font-size: 20px;
  }
  .self-tutorial-title {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 13px;
    color: $contrastColorDark;
  }
  .self-tutorial-progress-container {
    position: relative;
    top: 15px;
    display: flex;
    justify-content: center;
    .self-tutorial-progress {
      height: 4px;
      width: 90%;
      background-color: #e7e7e7;
      border-radius: 4px;
      .progress-bar {
        height: 4px;
        border-radius: 4px;
        background-color: #2ecc71;
        transition: width .6s;
      }
    }
  }
  .self-tutorial-text {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 240px;
    text-align: center;
    .fa-arrows-alt-h {
      padding: 0px 5px;
    }
    .fa-times-circle {
      position: static;
      padding: 0px 1px 0px 3px;
      font-size: 15px;
      &:hover {
        cursor: default;
        color: $main;
      }
    }
    .dropdown {
      display: inline;
      padding: 0px 5px;
      .fa-caret-down {
        padding-left: 3px;
        color: $main;
      }
    }
    .fa-play {
      color: $colorSuccess;
      padding: 0px 5px;
    }
    .fa-edit-container {
      font-size: 13px;
      display: inline;
      height: 50px;
      width: 50px;
      border-radius: 50%;
      padding: 0px 5px;
      .fa-edit {
        position: relative;
        top: -2px;
        right: -2px;
      }
    }
  }
  .buttons {
    display: flex;
    .next {
      @include box_shadow(1);
      margin: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40%;
      height: 50px;
      border-radius: 5px;
      background-color: $main;
      color: white;
      &:hover {
        cursor: pointer;
      }
      .fa-play {
        color: white;
        margin: 10px;
      }
    }
    .next-lock {
      background-color: $main;
      opacity: 0.8;
      &:hover {
        cursor: not-allowed;
      }
    }
  }
}
