@import '../../../variables';
@import '../../../mixins';

.learn-wrapper {
  display: flex;
  position: relative;
  flex-direction: column;
  @include breakpoint(laptop) {
    flex-direction: row;
  }
}

