@import '../../variables';
@import '../../mixins';

input {
 outline: none;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px white inset;
  -webkit-text-fill-color: $contrastColorDark !important;
}

button:focus {
  outline: none;
}

.wrapper {
  position: relative;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  .form {
    box-sizing: border-box;
    padding: 20px 10px;
    color: $main;
    position: relative;
    top: 30px;
    height: 460px;
    width: 400px;
    box-shadow: 3px 3px 15px -1px rgba(0,0,0,0.6);
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    .form-header {
      h3 {
        font-size: 1.3em;
      }
    }

    .input-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      .credential-input {
        height: 100%;
        width: 80%;
        padding-left: 10px;
        color: $contrastColorDark;
        background-color: inherit;
        border: none;
      }
      .username {
        color: black;
        height: 50px;
        width: 85%;
        margin: 3px 0px;
        border: 1px solid $main;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        overflow: hidden;
        .fa {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 5px;
          color: $main;
        }
      }
      .password {
        color: black;
        height: 50px;
        width: 85%;
        margin: 3px 0px;
        border: 1px solid $main;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        overflow: hidden;
        .fa {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 5px;
          color: $main;
        }
      }
      .error-message {
        margin-bottom: 5px;
        color: $colorDanger;
        width: 300px;
        font-size: 0.82em;
      }
      .input-error {
        border: 1px solid $colorDanger;
        color: $colorDanger;
      }
    }

    .action-button {
      position: relative;
      top: -30px;
      height: 52px;
      margin: 1.2em 1em;
      width: 85%;
      color: rgba(250,250,250,0.9);
      background-color: $main;
      border: 1px solid rgba(250,250,250,0.5);
      border-radius: 25px;
      &:hover {
        cursor: pointer;
      }
    }
    .action-button-disabled {
      &:hover {
        cursor: not-allowed;
      }
    }

    .password-panel {
      position: relative;
      top: -35px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      margin-top: 5px;
      width: 100%;
      font-size: .9em;
      .forgotPassword {
        width: 100%;
        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }

    .recaptcha-container {
      position: relative;
      top: -23px;
    }

    .horizontal-line {
      position: relative;
      width: 100%;
      padding: .5px;
      background-image: linear-gradient(to right, rgba(0, 0, 0, 0), $main, rgba(0, 0, 0, 0));
    }

    .register-panel {
      position: relative;
      top: -15px;
      .fa-user-edit {
        font-size: 1.05em;
        padding-left: 3px;
      }
      .register {
        span {
          color: $main;
          font-weight: 600;
          font-size: 1.05em;
          &:hover {
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }
    }

  }
}
