@import '../variables';
@import '../mixins';

.password-panel {
  width: 100%;
  .forgot-password {
    color: $main;
    margin: auto;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}
