@import '../../../mixins';
@import '../../../variables';

.learn-not-available-wrapper {
  height: 95%;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  .learn-not-available  {
    width: 90%;
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: white;
    color: $main;
    @include box_shadow(3);
    border: 1px solid rgba(0,0,0,0.2);
    border-radius: 4px;
    @include breakpoint(laptop) {
      width: 70%;
    }
    .learn-not-available-content {
      padding: 15px;
      display: flex;
      flex-direction: column;
      align-items: center;
      line-height: 2em;
      h2 {
        margin: 50px 0px;
      }
      @include breakpoint(laptop) {
        padding: 20px;
        line-height: 5.5em;
        h2 {
          margin: 15px 0px;
        }
      }
      .buy {
        width: 200px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 2px solid $main;
        border-radius: 3px;
        font-weight: 600;
        font-size: 1.2em;
        transition: all .3s;
        &:hover {
          cursor: pointer;
          background-color: $main;
          color: white;
          a {
            color: white;
          }
        }
        &:active {
          cursor: pointer;
          background-color: $main;
          color: white;
        }
        a {
          display: flex;
          justify-content: center;
          align-items: center;
          color: $main;
          text-decoration: none;
          width: 100%;
          height: 100%;
          text-align: center;
          &:focus {
            outline: none;
          }
        }
        &:hover {
          cursor: pointer;
          color: white;
        }
      }
    }
  }
}

