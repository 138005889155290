@import '../../../mixins';
@import '../../../variables';

.admin-container {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  color: $main;
  overflow-x: hidden;
  hr {
    line-height: 1px;
    color: black;
    width: 100vw;
    margin: 20px 0px;
  }
  .success {
    background-color: $colorSuccess !important;
  }
  .error {
    background-color: $colorDanger !important;
  }
  .pending {
    background-color: $colorWarning !important;
  }
  .availability-container {
    // background-color: red;
  }
  .coupon-container {
    // background-color: green;
    padding: 10px;
  }
  .users-container {
    // background-color: blue;
    padding: 10px;
  }
  form {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    // margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
    .form-availability, .form-email, .form-coupon {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      @include breakpoint(tablet) {
        flex-direction: row;
      }
      width: 100%;
      margin: 5px;
    }
    .form-email {
    }
    .form-coupon {
      // margin-top: 50px;
      input {
        margin: 10px;
      }
      input[type="submit"] {
        width: 100px;
      }
    }
    input[type="number"] {
      margin: 0px 10px;
      padding: 7px 10px;
      border: 1px solid $main;
      border-radius: 3px;
      width: 50px;
    }
    input[type="submit"] {
      margin: 3px;
      padding: 8px 10px;
      background-color: $main;
      color: white;
      border: none;
      border-radius: 3px;
      width: 200px;
      &:hover {
        cursor: pointer;
      }
    }
  }
  .table-coupons {
    overflow-x:auto;
    display: flex;
    width: 100vw;
    min-height: 153px;
    margin-top: 20px;
    margin-bottom: 50px;
    justify-content: center;
    align-items: center;
    @include breakpoint(tablet) {
      width: 750px;
    }
  }
  .table-users {
    overflow-x:auto;
    width: 100vw;
    display: flex;
    min-height: 300px;
    max-height: 500px;
    margin-top: 30px;
    margin-bottom: 50px;
    justify-content: center;
    @include breakpoint(tablet) {
      width: 750px;
    }
  }
  .fa-cog {
    padding: 50px;
  }
  h2 {
    padding: 50px 0px 20px;
  }
  .input-text-filter {
    margin: 0px 10px 0px;
    padding: 7px 10px;
    border: 1px solid $main;
    border-radius: 3px;
    width: 250px;
  }
  form {
    font-size: 1.1em;
  }
  table {
    border-collapse: collapse;
    overflow-x: auto;
    tr {
      border: 1px solid rgba(0,0,0,0.1);
      th {
        background-color: $main;
        color: white;
        border: 1px solid white;
      }
      td {
        border: 1px solid rgba(0,0,0,0.1);
        input[type="submit"] {
          margin: 3px;
          padding: 7px 10px;
          background-color: $main;
          color: white;
          border: none;
          border-radius: 3px;
          width: 100px;
          &:hover {
            cursor: pointer;
          }
        }
      }
      td.expired {
        color: $colorDanger;
      }
      td.active {
        color: $colorSuccess;
      }
      td, th {
        padding: 10px;
      }
      &:nth-child(2n) {
        background-color: rgba(0,0,0,0.1);
      }
    }
  }
}
