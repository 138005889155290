@import '../variables';
@import '../mixins';

.routes-enter {
  opacity: 0;
}
.routes-enter.routes-enter-active {
  opacity: 1;
  transition: opacity 200ms ease-in-out;
}
.routes-leave {
  opacity: 1;
}
.routes-leave.routes-leave-active {
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}

.course-page {
  position: relative;
  top: 50px;
  display: flex;
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
  flex-direction: column;
  height: auto;
  height: 100vh;
  @include breakpoint(laptop) {
    flex-direction: row;
  }
  .course-page-absolute-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    color: $mainDark;
  }
  /* width */
  ::-webkit-scrollbar {
      width: 7px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
      background: white;
      border: 1px solid white;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
      background: $contrastColorDark;
      border: 1px solid white;
      border-radius: 5px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: $contrastColorDark;
  }
}
