@import '../../../../variables';
@import '../../../../mixins';

.dictionary {
  z-index: 1;
  overflow: auto;
  color: $mainDark;
  height: 500px;
  min-width: 300px;
  max-width: 450px;
  display: none;
  @include breakpoint(laptop) {
    display: block;
    height: 100vh;
  }
  .dictionary-header {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 40px;
    align-items: center;
    height: 70px;
    padding-top: 28px;
    h3 {
      color: $contrastColorDark;
      font-size: 20px;
      font-weight: 400;
      font-family: 'Lato', sans-serif;
    }
  }
  ul {
    list-style-type: none;
    padding: 0px 20px 70px 20px;
    text-align: left;
  }
  li {
    span {
      font-weight: 600;
    }
    &:hover {
      text-decoration: underline;
      cursor: pointer;
      color: $contrastColorDark;
    }
  }
}
