@import '../../mixins';
@import '../../variables';

.about-content {
  margin-top: 0px;
  height: auto;
  background-color: #f6f6f6;
  width: auto;
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  @include breakpoint(laptop) {
    flex-direction: row;
  }
  justify-content: space-between;
  align-items: center;
  color: $contrastColorDark;
  -webkit-transform: translateZ(0);
  font-family: 'Lato', 'Montserrat';
  .about-description-container {
    position: relative;
    min-height: 500px;
    width: 90%;
    padding: 30px 60px 50px 60px;
    z-index: 2;
    overflow: hidden;
    @include breakpoint(tablet) {
      min-height: 450px;
      width: 70%;
      padding: 50px 60px 50px 60px;
    }
    @include breakpoint(laptop) {
      width: 60%;
      padding: 30px 70px 60px 70px;
    }
    text-align: left;
    -webkit-transform:translate3d(0,0,0);
    &:hover .about-description .slide {
      opacity: 1;
    }
    h3 {
      color: $main;
    }
    a {
      color: $contrastColorDark;
      text-decoration: none;
      font-weight: 600;
      &:hover {
        text-decoration: underline;
      }
    }
    .about-description {
      z-index: 2;
      min-height: 100%;
      position: relative;
      font-size: 16px;
      @include breakpoint(tablet) {
        font-size: 19px;
      }
      @include breakpoint(laptop) {
        font-size: 19px;
      }
      .swipe-info {
        position: absolute;
        top: 380px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: $main;
        opacity: 0.4;
        transition: opacity .4s;
        .fa-hand-point-up {
          position: relative;
          top: -5px;
          left: 5px;
        }
      }
      .swipe-info-hidden {
        opacity: 0;
      }
      .slide {
        color: $main;
        opacity: 0;
        position: absolute;
        top: 0px;
        bottom: 0px;
        height: 400px;
        width: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 600ms opacity linear;
        display: none;
        &:hover {
          cursor: pointer;
        }
        @include breakpoint(tablet) {
          display: flex;
          width: 120px;
        }
        @include breakpoint(laptop) {
          width: 50px;
        }
      }
      .slide-left {
        @extend .slide;
        left: -120px;
        @include breakpoint(laptop) {
          left: -60px;
        }
      }
      .slide-right {
        @extend .slide;
        right: -120px;
        @include breakpoint(laptop) {
          right: -50px;
        }
      }
    }
  }
  .about-image {
    position: relative;
    height: 100vh;
    z-index: 5;
    width: 100%;
    @include breakpoint(laptop) {
      width: 50%;
    }
    .about-avatar {
      background-image: url('../../assets/lena.jpg');
      width: 100%;
      height: 100%;
      margin: 0px;
      margin-right: 20px;
      z-index: 5;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
    .about-overlay {
      position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
      height: 500px;
      width: 100%;
      z-index: 5;
      background-image: linear-gradient(to bottom, white, transparent 20%);
    }
  }
  .slider {
    z-index: 3;
    position: absolute;
    left: 0px;
    bottom: 20px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .slider div {
    z-index: 3;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin: 5px;
    border: 3px solid $main;
    &:hover {
      cursor: pointer;
    }
  }
}


.slide-left-enter {
  opacity: 0;
  transform: translateX(1000px);
}
.slide-left-enter-active {
  opacity: 1;
  transform: translateX(0px);
  transition: transform 600ms ease, opacity 400ms linear;
}
.slide-left-exit {
  opacity: 1;
  transform: translateX(0px);
}
.slide-left-exit-active {
  opacity: 0;
  transform: translateX(-1000px);
  transition: transform 600ms ease, opacity 400ms linear;
}

.slide-right-enter {
  opacity: 0;
  transform: translateX(-1000px);
}
.slide-right-enter-active {
  opacity: 1;
  transform: translateX(0px);
  transition: transform 600ms ease, opacity 400ms linear;
}
.slide-right-exit {
  opacity: 1;
  transform: translateX(0px);
}
.slide-right-exit-active {
  opacity: 0;
  transform: translateX(1000px);
  transition: transform 600ms ease, opacity 400ms linear;
}

.description {
  position: absolute;
}
