@import '../../../../../mixins';
@import '../../../../../variables';

.profile-menu {
  width: 200px;
  min-height: 300px;
  background-color: $main;
  color: white;
  position: relative;
  bottom: 0px;
  left: 0px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @include box_shadow(3);
  .profile-menu-dropdown {
    list-style-type: none;
    padding: 10px;
    line-height: 3.5em;
    width: 100%;
    .fa-angle-right {
      display: none;
    }
    .is-active {
      font-weight: 600;
    }
    .is-active + .fa-angle-right {
      display: block;
      font-weight: 600;
    }
    li {
      display: flex;
      justify-content: center;
      div {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: center;
        width: 100%;
      }
      a {
        color: white;
        text-decoration: none;
        padding: 10px 20px;
        &:focus {
          outline: none;
        }
      }
    }
  }
}
