@import '../../../mixins';
@import '../../../variables';

.newsletter-modal {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  .newsletter {
    position: relative;
    width: 300px;
    height: 480px;
    display: flex;
    flex-direction: column;
    align-items: space-evenly;
    justify-content: center;
    @include breakpoint(phoneHorizontal) {
      width: 500px;
      height: 330px;
    }
    background-color: white;
    border-radius: 3px;
    @include box_shadow(5);
    padding: 20px;
    color: $contrastColorDark;
    span {
      font-weight: 600;
      font-size: 1.3em;
      letter-spacing: 1.1px;
      padding: 2px;
    }
    .discount {
      margin-bottom: 25px;
    }
    .email-container {
      display: flex;
      flex-direction: column;
      margin-top: 25px;
      label {
        text-indent: 4px;
      }
      .email-submit {
        form {
          display: flex;
        }
        input[type="email"] {
          box-sizing: border-box;
          width: 70%;
          margin: 4px;
          height: 40px;
          text-indent: 10px;
          border: none;
          border: 1px solid $main;
        }
        button[type="submit"] {
          position: relative;
          top: 4px;
          left: -4px;
          box-sizing: border-box;
          width: 30%;
          background-color: $main;
          color: #fff;
          border: none;
          height: 40px;
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
    .caption {
      position: absolute;
      bottom: 10px;
      font-size: .8em;

    }
    .close-container {
      position: absolute;
      top: 3%;
      right: 3%;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 15px;
      height: 15px;
      color: $main;
      &:hover {
        cursor: pointer;
      }
    }
    h2 {
      font-size: 1.6em;
      text-align: center;
      font-weight: 300;
      color: $main;
      margin-top: 10px;
    }
    ul {
      padding-left: 0px;
      margin-top: -10px;
      list-style-type: none;
      li {
        line-height: 1.4em;
        .fa-check {
          position: static;
          color: $colorSuccess;
        }
      }
    }
  }
}

