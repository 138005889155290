@import '../../../mixins';
@import '../../../variables';

.logo {
  display: flex;
  align-items: center;
  .pill {
    display: flex;
    transform: rotate(-45deg);
    div {
      width: 22px;
      height: 20px;
      border: 1px solid $main;
    }
    .pill-left {
      position: relative;
      border-top-left-radius: 50%;
      border-bottom-left-radius: 50%;
      background-color: $main;
      .pill-left-shadow {
        position: absolute;
        top: 4px;
        left: 6px;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: white;
        border: none;
      }
    }
    .pill-right {
      position: relative;
      border-top-right-radius: 50%;
      border-bottom-right-radius: 50%;
      .pill-right-shadow {
        position: absolute;
        top: 2px;
        right: 3px;
        width: 15px;
        height: 7px;
        border-color: white;
        border-top: 1px solid $main;
        border-right: 1px solid $main;
        border-left: 1px solid transparent;
        border-bottom: 1px solid transparent;
        border-top-right-radius: 50%;
        border-bottom-right-radius: 30%;
      }
    }
  }
  .logo-label {
    display: none;
    width: 200px;
    color: $main;
    padding: 15px 15px 15px 0px;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 1.5px;
    @include breakpoint(tablet) {
      display: block;
    }
  }
}
