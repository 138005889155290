@import 'src/variables';
@import 'src/mixins';

.reset-password-container {
  display: flex;
  flex-direction: column;
  color: $main;
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .error-message {
      color: $colorDanger;
      padding-bottom: 15px;
    }
    input[type="email"] {
      width: 300px;
      height: 46px;
      border-radius: 3px;
      border: 1px solid $main;
      margin: 10px;
      text-indent: 10px;
    }
    button[type="submit"] {
      width: 300px;
      background-color: $main;
      border-radius: 25px;
      border: 1px solid $main;
      color: #fff;
      height: 50px;
      transition: box-shadow .2s;
      box-shadow: 1px 4px 20px -2px rgba(0,0,0,0.6);
      &:hover {
        cursor: pointer;
        box-shadow: 1px 4px 15px -2px rgba(0,0,0,0.6);
      }
      &:active {
        cursor: pointer;
        box-shadow: 1px 4px 10px -2px rgba(0,0,0,0.6);
      }
    }
  }
}
