@import '../../mixins';
@import '../../variables';

$sectionHeight: 120px;

.footer {
  height: auto;
  background-color: $mainDark;
  color: white;
  width: auto;
  position: relative;
  z-index: 2;
  padding: 70px 0px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  justify-content: space-between;
  @include breakpoint(laptop) {
    flex-direction: row;
    padding: 70px 70px 80px;
  }
  -webkit-transform:translate3d(0,0,0);
  .footer-rights {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .paypal {
      position: relative;
      top: 35px;
    }
    .logo {
      margin-bottom: 20px;
      @include breakpoint(laptop) {
        margin: 0px;
      }
      .logo-label {
        color: white;
      }
    }
    a {
      color: inherit;
      text-decoration: none;
      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
    .pill {
      .pill-left {
        border: 1px solid white;
        background-color: white;
        .pill-left-shadow {
          background-color: $main;
        }
      }
      .pill-right {
        border: 1px solid white;
        .pill-right-shadow {
          border-color: $main;
          border-top: 1px solid white;
          border-right: 1px solid white;
        }
      }
    }
    .navigation-logo {
      margin-top: -20px;
      margin-bottom: 20px;
    }
    .navigation-logo-label {
      color: white;
    }
  }
  .first-column {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    .footer-contact {
      height: $sectionHeight;
      margin: 30px 0px;
      h3 {
        margin-top: 50px;
      }
      @include breakpoint(laptop) {
        margin: 10px 0px;
        h3 {
          margin-top: 1em;
        }
      }
      &::before {
        opacity: 0.3;
        content: '';
        display: block;
        height: 1px;
        width: 100%;
        margin: 20px 0px;
        background-color: #E5E5E5;
        @include breakpoint(laptop) {
          display: none;
        }
      }
    }
    .footer-materials {
      height: $sectionHeight;
      margin: 10px 0px;
      padding: 0px 20px;
      @include breakpoint(laptop) {
        margin: 10px 0px;
      }
      a {
        color: white;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  .second-column {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    justify-content: space-evenly;
    @include breakpoint(laptop) {
      width: 280px;
    }
    .footer-terms {
      height: $sectionHeight;
      margin: 10px 0px;
      text-align: center;
      @include breakpoint(laptop) {
        text-align: left;
        margin: 10px 0px;
      }
      a {
        color: #fff;
        text-decoration: none;
        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }
      h3 {
        margin-top: 50px;
      }
      @include breakpoint(laptop) {
        margin: 10px 0px;
        h3 {
          margin-top: 1em;
        }
      }
      &::before {
        opacity: 0.3;
        content: '';
        display: block;
        height: 1px;
        width: 100%;
        background-color: #E5E5E5;
        margin: 20px 0px;
        @include breakpoint(laptop) {
          display: none;
          margin: 0px;
        }
      }
    }
    .footer-social {
      display: flex;
      flex-direction: column;
      position: relative;
      width: 100vw;
      height: $sectionHeight;
      margin: 15px 0px;
      text-align: left;
      justify-content: center;
      align-items: center;
      top: 20px;
      @include breakpoint(laptop) {
        justify-content: flex-start;
        align-items: flex-start;
        position: static;
        width: auto;
        top: 0px;
        margin: 10px 0px;
      }
      .footer-social-title {
        display: flex;
        .fa-facebook-square {
          position: relative;
          top: 13px;
          left: 13px;
          color: #fff;
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
}
