@import '../../../../variables';
@import '../../../../mixins';

.dropdown-container {
  position: relative;
  background-color: $main;
  display: flex;
  flex-direction: column;
  color: white;
  padding: 7px;
  // border-radius: 4px;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  @include box_shadow(1);
  .dropdown {
    background-color: $main;
    display: flex;
    align-items: center;
    &:hover {
      cursor: pointer;
    }
  }
  .dropdown-options {
    background-color: $main;
    position: absolute;
    top: 30px;
    left: 0px;
    padding: 7px;
    width: 42px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    transition: opacity .2s;
    @include box_shadow(1);
    &:hover {
      cursor: pointer;
    }
  }
  .flag {
    width: 20px;
    border: 1px solid rgba(0,0,0,0.5);
    margin: 0px 5px;
    &:hover {
      cursor: pointer;
    }
  }
  .language-dropdown {
    display: flex;
    flex-direction: column;
  }
}
.dropdown-container-open {
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}
.dropdown-mobile {
  position: absolute;
  top: 35px;
  right: 20px;
}
