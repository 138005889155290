@import '../../../mixins';
@import '../../../variables';
@import './courseNavigationTabs/CourseNavigationTabs';

.course-navigation {
  position: fixed;
  top: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 18px 25px;
  color: white;
  z-index: 101;
  height: 50px;
  font-size: 18px;
  font-family: 'lato', sans-serif;
  transition: .4s height;
  height: 20px !important;
  .logo {
    &:hover {
      cursor: pointer;
    }
  }
}
.course-navigation-sticky {
  background-color: $main;
  color: white;
  height: 30px;
  @include box_shadow(2);
  .logo {
    .pill {
      .pill-left {
        border: 1px solid white;
        background-color: white;
        .pill-left-shadow {
          background-color: $main;
        }
      }
      .pill-right {
        border: 1px solid white;
        .pill-right-shadow {
          border-color: $main;
          border-top: 1px solid white;
          border-right: 1px solid white;
        }
      }
    }
    .logo-label {
      color: white;
    }
  }
  .course-navigation-tabs {
    background-color: $main;
    .course-navigation-tab {
      color: white;
    }
  }
  .hamburger-menu {
    .hamburger {
      & > span {
        background-color: white;
        &::before, &::after {
          background-color: white;
        }
      }
    }
    .close:focus > span {
      background-color: transparent;
    }
  }
}
