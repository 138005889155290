@import 'src/variables';
@import 'src/mixins';

.purchase-confirmation {
  h3 {
    color: $main;
  }
  table {
    border-collapse: collapse;
    color: $main;
    border: 1px solid $main;
    .coupon {
      color: #fff;
      background-color: $main;
    }
    .bold {
      font-weight: 600;
      font-size: 1.1em;
      text-align: right;
      span {
        margin: 10px;
      }
    }
    td, th {
      padding: 15px;
      border: 1px solid $main;
    }
  }
  .information {
    position: relative;
    border: 1px solid $colorDanger;
    color: $colorDanger;
    padding: 15px 20px;
    border-radius: 5px;
    margin: 40px;
    font-size: .8em;
    width: 70%;
    i {
      position: absolute;
      top: -15px;
      left: -15px;
      background-color: white;
    }
  }
  .purchase-confirmation-buttons {
    width: 56%;
    color: $main;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    div {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      top: -30px;
      height: 52px;
      margin: 1.2em 1em;
      width: 200px;
      color: rgba(250,250,250,0.9);
      background-color: $main;
      border: 1px solid rgba(250,250,250,0.5);
      border-radius: 5px;
      &:hover {
        cursor: pointer;
      }
    }
    .purchase-confirmation-cancel {
      background-color: $colorDanger;
    }
  }
}
