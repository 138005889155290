@import '../../../../variables';
@import '../../../../mixins';

.message {
  position: absolute;
  width: 100%;
  height: 50px;
  top: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
  transition: top .6s, opacity .6s;
  opacity: 1;
  color: white;
  @include box_shadow(1);
  .fa-times {
    position: absolute;
    right: 10px;
    top: 13px;
    padding: 5px;
    &:hover {
      cursor: pointer;
    }
  }
}

.message-add {
  background-color: $colorSuccess;
  opacity: 1;
}

.message-remove {
  background-color: $colorDanger;
  opacity: 1;
}

.message-warning {
  background-color: $colorWarning;
  opacity: 1;
}

.message-hidden {
  top: -50px;
  opacity: 0;
  transition: top .6s, opacity .6s;
}
