@import '../../variables';
@import '../../mixins';

.wrapper {
  position: relative;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  .content {
    color: $main;
    width: 500px;
    height: auto;
    line-height: 1.5em;
    h2 {
      color: $colorSuccess;
    }
    h4 {
      margin-top: 30px;
      margin-bottom: 30px;
    }
    .fa-check-circle {
      color: $colorSuccess;
    }
    .homepage-button {
      position: relative;
      top: 10px;
      background-color: $main;
      color: white;
      font-weight: 500;
      font-size: 16px;
      padding: 25px 40px;
      border-radius: 3px;
      border-width: 0px;
      z-index: 3;
      outline: none;
      box-shadow: 1px 4px 20px -2px rgba(0,0,0,0.6);
      &:hover {
        cursor: pointer;
        background-color: $mainLight;
        box-shadow: 1px 4px 15px -2px rgba(0,0,0,0.6);
      }
      &:active {
        cursor: pointer;
        background-color: $contrastColorLight;
        box-shadow: 1px 4px 10px -2px rgba(0,0,0,0.6);
      }
    }
  }
}
