@import 'src/variables';
@import 'src/mixins';

.shop-offer {
  position: relative;
  top: -20px;
  //@include box_shadow(3);
  min-height: 400px;
  width: 248px;
  text-align: left;
  padding: 50px 25px;
  margin-top: 60px;
  border-radius: 4px;
  padding-top: 50px;
  color: $contrastColorDark;
  .shop-offer-container {
    padding-top: 40px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
    height: 45%;
    width: 100%;
    justify-content: space-evenly;
    align-items: flex-start;
    font-size: 1.1em;
    .shop-offer-duration {
      position: relative;
      top: -10px;
      line-height: 2em;
      label {
        display: flex;
        align-items: center;
        text-indent: 20px;
        &:hover {
          cursor: pointer;
        }
      }
    }
    span {
      font-size: 1.1em;
    }
    .buy {
      position: absolute;
      bottom: 20px;
      background-color: $main;
      border: none;
      color: #fff;
      width: 80%;
      height: 50px;
      border-radius: 4px;
      font-weight: 600;
      font-size: 1em;
      @include box_shadow(2);
      &:hover {
        cursor: pointer;
      }
    }
    .buy-disabled {
      &:hover {
        cursor: not-allowed;
      }
    }
  }
  .shop-offer-title {
    position: absolute;
    top: 0px;
    left: 0px;
    color: white;
    background-color: $main;
    height: 60px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    //border-top-right-radius: 4px;
    //border-top-left-radius: 4px;
    font-size: 1.2em;
  }
  .shop-offer-availability {
    position: relative;
    line-height: .7em;
    width: 100%;
    color: $colorDanger;
  }
  .coupon-accept {
    color: $colorSuccess;
    font-size: .9em;
  }
  .coupon-input-wrapper {
    width: 100%;
  }
  .coupon-input-container {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    width: 100%;
    .coupon-input {
      width: calc(100% - 50px);
      height: 21px;
      padding: 5px;
      border: 1px solid $main;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }
    .coupon-input-error {
      border: 1px solid $colorDanger;
    }
    .coupon-add {
      background-color: $main;
      color: white;
      width: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px 10px;
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
      &:hover {
        cursor: pointer;
      }
    }
  }
  .coupon-error {
    color: $colorDanger;
    font-size: 0.78em;
  }
  .coupon {
    position: relative;
    top: -5px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 5px 0px;
    height: 23px;
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .shop-offer-price {
    font-weight: 600;
    font-size: 1.3em;
    span {
      color: $contrastColorDark;
    }
  }
  .accept-terms {
    a {
      color: $main;
    }
    &:hover {
      cursor: pointer;
    }
  }
  .traditional-transfer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 35px;
    border-radius: 3px;
    margin-bottom: 15px;
    background-color: $main;
    color: white;
    font-weight: 600;
    &:hover {
      cursor: pointer;
      background-color: $mainDark;
    }
  }
  .traditional-transfer-disallow {
    &:hover {
      cursor: not-allowed !important;
    }
  }
  .payment-method-dividor {
    position: relative;
    margin: 20px;
    span {
      background-color: white;
      color: $main;
      padding: 0px 10px;
      position: absolute;
      top: -10px;
      left: calc(50% - 22px);
    }
  }
  .paypal-button {
    margin-bottom: 0px;
    padding: 20px 0px;
  }
  .privat {
    font-size: 0.9em;
    padding-bottom: 70px;
  }
}

