@import '../../mixins';
@import '../../variables';

$questionAnswerWidthSmall: 350px;
$questionAnswerWidth: 500px;
$questionAnswerWidthBig: 600px;

.faq-content {
  margin-top: 0px;
  padding-bottom: 80px;
  min-height: 100vh;
  background-color: #fff;
  width: auto;
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: $contrastColorDark;
  -webkit-transform: translateZ(0);
  font-family: 'Lato', 'Montserrat';
  -webkit-transform:translate3d(0,0,0);
  color: $main;
  .faq-title {
    display: flex;
    align-items: center;
    margin-top: 80px;
    margin-bottom: 30px;
    justify-content: space-between;
    width: $questionAnswerWidthSmall;
    @include breakpoint(phoneHorizontal) {
      width: $questionAnswerWidth;
    }
    @include breakpoint(tablet) {
      width: $questionAnswerWidthBig;
    }
    h2 {
      font-weight: 300;
    }
  }
  .faq-questions-answers-list {
    padding: 0px;
    .faq-question-answer-container {
      list-style-type: none;
      margin: 14px;
      width: $questionAnswerWidthSmall;
      @include breakpoint(phoneHorizontal) {
        width: $questionAnswerWidth;
      }
      @include breakpoint(tablet) {
        width: $questionAnswerWidthBig;
      }
      .faq-question {
        padding: 10px 20px;
        height: 40px;
        font-weight: 600;
        color: $main;
        border: 1px solid $main;
        border-radius: 4px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-align: left;
        &:hover {
          cursor: pointer;
        }
        .fa-caret-down {
          padding: 10px;
        }
      }
      .faq-answer-container {
        overflow: hidden;
        z-index: 1;
        height: auto;
        height: 100%;
        width: 100%;
        .faq-absolute-wrapper {
          .faq-answer {
            justify-content: flex-start;
            height: 100%;
            width: 100%;
            padding: 20px 10px;
            box-sizing: border-box;
            display: block;
            position: relative;
            z-index: 1;
            width: 100%;
            text-align: justify;
            white-space: pre-line;
            a {
              color: $contrastColorDark;
              text-decoration: none;
              font-weight: 600;
              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
  }
}

.faq-slide-top-enter {
  transform: translateY(-100%);
  opacity: 0;
}
.faq-slide-top-enter-active {
  transform: translateY(0px);
  transition: transform 300ms linear, opacity 300ms;
  opacity: 1;
}
.faq-slide-top-exit {
  transform: translateY(0px);
  opacity: 1;
}
.faq-slide-top-exit-active {
  transform: translateY(-100%);
  transition: transform 300ms linear, opacity 300ms;
  opacity: 0;
}
