@import '../../../mixins';
@import '../../../variables';

.navigation {
  position: fixed;
  top: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 18px 25px;
  color: white;
  z-index: 5;
  height: 50px;
  font-size: 18px;
  font-family: 'lato', sans-serif;
  transition: .4s height;
  height: 20px !important;
  .logo {
    &:hover {
      cursor: pointer;
    }
  }
}
.navigation-sticky {
  background-color: $main;
  color: white;
  height: 30px;
  @include box_shadow(2);
  .logo {
    .pill {
      .pill-left {
        border: 1px solid white;
        background-color: white;
        .pill-left-shadow {
          background-color: $main;
        }
      }
      .pill-right {
        border: 1px solid white;
        .pill-right-shadow {
          border-color: $main;
          border-top: 1px solid white;
          border-right: 1px solid white;
        }
      }
    }
    .logo-label {
      color: white;
    }
  }
  .navigation-tabs {
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-weight: 500;
    font-size: 20px;
    height: 50px;
    position: relative;
    flex-direction: row;
    background-color: transparent;
    padding-right: 50px;
    .navigation-tab {
      display: flex;
      justify-content: center;
      align-items: center;
      height: auto;
      width: auto;
      @include breakpoint(laptop) {
        padding: 0px 10px;
      }
      color: white;
      border: 2px solid transparent;
      border-radius: 3px;
      .fa-arrow-circle-left {
        padding-left: 10px;
      }
    }
    .navigation-tab-goback {
      &:hover {
        cursor: pointer;
      }
    }
  }
  .hamburger-menu {
    .hamburger {
      & > span {
        background-color: white;
        &::before, &::after {
          background-color: white;
        }
      }
    }
    .close:focus > span {
      background-color: transparent;
    }
  }
}
