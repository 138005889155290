@import '../../../mixins';
@import '../../../variables';

.landing-navigation-tabs {
  position: absolute;
  top: 0px;
  left: 0px;
  flex-direction: column-reverse;
  background-color: rgb(250,250,250);
  z-index: 10;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-weight: 500;
  font-size: 20px;
  -webkit-transform:translate3d(0,0,0);
  @include breakpoint(laptop) {
    position: relative;
    flex-direction: row-reverse;
    background-color: transparent;
    height: 60px;
  }
  .landing-navigation-tab {
    height: auto;
    width: auto;
    padding: 15px 20px;
    color: $main;
    border: 2px solid transparent;
    border-radius: 3px;
    transition: all .2s;
    &:hover {
      border: 2px solid $main;
      cursor: pointer;
    }
  }
}
