@import '../../mixins';
@import '../../variables';
@import './offerOption/OfferOption.scss';

.offer-content {
  margin-top: 100vh;
  min-height: 100vh;
  @include breakpoint(laptop) {
    height: 100vh;
  }
  background-color: #fff;
  width: auto;
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  overflow: hidden;
  padding: 30px 0px 0px 0px;
  -webkit-transform: translateZ(0);
  @include breakpoint(laptop) {
    flex-direction: row;
  }
}
