@import '../variables';
@import '../mixins';

.scroll-to-top {
  position: fixed;
  right: 6%;
  bottom: 6%;
  height: 56px;
  width: 56px;
  border-radius: 50%;
  font-size: 20px;
  background-color: $main;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  @include box_shadow(4);
  transition: bottom .5s;
  &:hover {
    cursor: pointer;
  }
}
.scroll-to-top-hidden {
  bottom: -100px;
}
