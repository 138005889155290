@import '../../../mixins';
@import '../../../variables';

.account-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  .account-info {
    position: relative;
    top: -20px;
    width: 330px;
    height: 440px;
    color: $main;
    display: flex;
    flex-direction: column;
    padding: 20px;
    @include breakpoint(laptop) {
      padding: 30px;
      border: 1px solid rgba(0,0,0,0.1);
      border-radius: 3px;
      @include box_shadow(3);
    }
    .courses-availability {
      div {
        padding: 5px 0px;
      }
    }
    .fa-user-md {
      margin-right: 20px;
      margin-bottom: 10px;
    }
    div {
      .expired {
        color: $colorDanger;
      }
      .active {
        color: $colorSuccess;
      }
    }
    p {
      text-align: left;
      .label {
        font-weight: 600;
        font-size: 1.1em;
        padding: 10px 0px;
        margin-left: 5px;
        margin-bottom: 20px;
      }
      .expired {
        color: $colorDanger;
      }
      .active {
        color: $colorSuccess;
      }
    }
  }
}

