@import '../../../mixins';
@import '../../../variables';

.profile-button {
  display: flex;
  justify-content: center;
  align-items: center;
  .profile-icon {
    width: 70px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 2px solid $main;
    z-index: 15;
    .fa-user-md {
      color: $main;
    }
  }
  .profile-icon-sticky {
    border: none;
    .fa-user-md {
      color: white;
    }
  }
}
